<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Número *" />
      <BorderInput
        v-model="parking.data.number"
        label="Número *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="parking.rules.number"
        message="Ingrese un número de estacionamiento"
      />
      <BasicLabel v-if="false" label="Prorrateo" />
      <BorderInput
        v-if="false"
        v-model="parking.data.apportionment"
        label="Prorrateo"
        @keyup.enter="onSave"
      />
      <FormError :show="parking.rules.apportionment" />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const parking = reactive({
      data: {
        id: "",
        buildingId: store.state.general.selectedBuilding,
        number: "",
        apportionment: "",
      },
      rules: {
        number: false,
        apportionment: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!parking.data.number) {
        parking.rules.number = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: parking.data.id,
        buildingId: store.state.general.selectedBuilding,
        number: parking.data.number,
        apportionment: parking.data.apportionment,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(actions.parkingActions.update, request);
      } else {
        response = await store.dispatch(actions.parkingActions.create, request);
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedParkings", []);
        router.push("/estacionamientos/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/estacionamientos/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.parking.selectedParkings[0];
        parking.data.id = selected.id;
        parking.data.number = selected.number;
        parking.data.apportionment = selected.apportionment;
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (parking.data.number) parking.rules.number = false;
      if (parking.data.apportionment) parking.rules.apportionment = false;
    });

    return { store, parking, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
